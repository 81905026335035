/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ruthereEventsApi",
            "endpoint": "https://y9k66f98ab.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4md5wtjb2fgvbmzn7ktnkpye3i.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4rbhc5x7rbhblfmzts6ujifnk4",
    "aws_cognito_identity_pool_id": "ca-central-1:77153809-b725-4c59-baf6-148195df79e3",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_1AinkF21h",
    "aws_user_pools_web_client_id": "5nthu02afdo9vvrgt86pbe79pq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ruthereconsolegood01038b46d6544865bbf24f5b9e73f171148-prod",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
